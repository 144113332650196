<template>
  <div class="tutorDetail" @click="tutorDetail">
    <div class="main">
      <div class="left">
        <div class="center">
          <div class="flex_cup">
            <div class="avatar">
              <img :src="detailData.avatar" alt="" />
            </div>
            <div class="infor">
              <h3>{{ detailData.nickname }}</h3>
              <h4>{{ detailData.post }}</h4>
              <div class="tag" v-if="tags.length > 0">
                <span v-for="(item, index) in tags" :key="index">{{
                  item
                }}</span>
              </div>
              <div class="button">
                <span @click="showChat">去咨询</span>
                <span @click="ifpop">在线留言</span>
              </div>
              <p class="p">
                已有<i>{{ detailData.consultNumber }}</i
                >人咨询
              </p>
            </div>
          </div>
        </div>
        <div class="center center2">
          <div class="part">
            <h5>
              <span>
                <img src="../../assets/detail-icon1.png" alt="" />
              </span>
              <p>个人介绍</p>
            </h5>
            <p class="detail">
              <!-- {{detailData.individualResume | resumeFilter }} -->
              {{ detailData.individualResume }}
            </p>
          </div>
          <div class="part">
            <h5>
              <span>
                <img src="../../assets/detail-icon2.png" alt="" />
              </span>
              <p>擅长的问题</p>
            </h5>
            <p class="detail">
              {{ detailData.fendaIntroduction }}
            </p>
          </div>
        </div>
        <div class="bottom">
          <h3>问答案例</h3>
          <div class="card">
            <div
              class="item"
              v-for="(item, index) in detailData.answerList"
              :key="index"
            >
              <div class="top">
                <i class="tag">
                  <img src="../../assets/detail-icon3.png" alt="" />
                </i>
                <p>
                  {{ item.question | questionFilter }}
                </p>
              </div>
              <div class="float">
                <div class="answer">
                  <div class="img_cup">
                    <img :src="item.masterAvatar" alt="" />
                  </div>
                  <div
                    class="audio"
                    @click="plays(`audio${index}`, item.answerSeconds, index)"
                  >
                    <i class="time">{{ item.answerSeconds | transTime }}</i>
                    <span class="line"></span>
                    <!-- <span class="line" :class="{'playing':ifTrue,'pause':!ifTrue}"></span> -->
                    <!-- <i>{{ durations[index].duration | transTime }}</i> -->
                    <audio
                      class="audiocontrol"
                      :ref="`audio${index}`"
                      :src="item.answer"
                      type="audio/mp3"
                      controls="controls"
                      @pause="is_stop = true"
                      @play="is_stop = false"
                    ></audio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreinfor" @click.stop="moreinfor">
            <div class="codes" v-show="ifshow">
              <img src="../../assets/download-qrcode.png" alt="" />
            </div>
            <div class="icon">
              <img src="../../assets/more.png" alt="" />
            </div>
            <p>想查看更多内容，请下载【师董会APP】查看</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="onlineMsg">
          <div class="floatMsg" @click="ifpop">
            <p>3000+<span>位在线导师</span></p>
            <i>在线留言</i>
          </div>
        </div>
        <div class="recommend">
          <h4>相关导师推荐</h4>
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in detailData.recommendList"
              :key="index"
            >
              <div class="top">
                <div class="img_c">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="text">
                  <h5>{{ item.nickname }}</h5>
                  <p>{{ item.post }}</p>
                </div>
              </div>
              <div class="btn">
                <p>已有{{ item.consultNumber }}人咨询</p>
                <span @click="tutorDetails(item.userId)">立即咨询</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var durationshow;
import request from "@/utils/request";
var detailData = {}; //详情数据
export default {
  name: "Tutor-detail",
  data() {
    return {
      timer: null,
      tags: [],
      ifTrue: false,
      is_stop: true,
      ifshow: false,
      duration: 0,
      durations: [],
      detailData: {},
      screenData: {
        pageTo: 1,
        pageSize: 10,
      },
    };
  },
  filters: {
    questionFilter(val) {
      let question = "";
      if (val.length > 50) {
        question = val.slice(0, 50) + "...";
      } else {
        question = val;
      }
      return question;
    },
    transTime(time) {
      // console.log(time);
      durationshow = parseInt(time);
      var minute = parseInt(durationshow / 60);
      var sec = (durationshow % 60) + "";
      var isM0 = ":";
      if (minute == 0) {
        minute = "00";
      } else if (minute < 10) {
        minute = "0" + minute;
      }
      if (sec.length == 1) {
        sec = "0" + sec;
      }
      return minute + isM0 + sec;
    },
  },
  methods: {
    // compar(array, key) {
    //   return array.sort(function (a, b) {
    //     const x = a[key];
    //     const y = b[key];
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   });
    // },
    // showLong(el, index) {
    //   //音频加载成功后获取时长
    //   console.log(el, index);
    //   this.duration = parseInt(this.$refs[el][0].duration);
    //   this.durations.push({
    //     num: index,
    //     duration: this.duration,
    //   });
    //   // this.durations = this.compar(this.durations, "num");
    //   console.log(this.duration);
    //   console.log(this.durations);
    // },
    tutorDetails(userId){
      window.open("/tutor-detail?userId=" + userId);
    },
    showChat(){
        this.$store.commit('showChat')
    },
    ifpop() {
      this.$store.commit('showPopMessage')
    },
    tutorDetail() {
      this.ifshow = false;
    },
    moreinfor() {
      this.ifshow = true;
    },
    plays(el, time, indexs) {
      //播放暂停控制
      console.log(this.$refs[el][0]);
      console.log(this.$refs[el]);
      console.log(time);
      console.log(indexs);
      if (this.is_stop) {
        this.$refs[el][0].play();
        console.log(this.$refs[el][0].currentTime);
        console.log(detailData);
        console.log(this.detailData);
        detailData.answerList.forEach((item, index) => {
          if (index == indexs) {
            this.timer = setInterval(() => {
              item.answerSeconds--;
              if (item.answerSeconds <= 0) {
                item.answerSeconds=this.$refs[el][0].duration;
                // this.$refs[el][0].pause();
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        });
      } else {
        this.$refs[el][0].pause();
        console.log(this.$refs[el][0].value);
        detailData.answerList.forEach((item, index) => {
          if (index == indexs) {
            item.answerSeconds=this.$refs[el][0].duration-this.$refs[el][0].currentTime;
            clearInterval(this.timer);
            this.timer=null;
            // if (item.answerSeconds <= 0) {
            //     this.$refs[el][0].pause();
            //     clearInterval(this.timer);
            //     this.timer = null;
            //     item.answerSeconds=this.$refs[el][0].duration;
            //   }
          }
        });
      }
    },
    detailShow() {
      console.log(this.$route);
      request
        .post("/in/website/mentorDetails", {
          userId: this.$route.query.userId,
        })
        .then((res) => {
          detailData = res.data;
          console.log(detailData);
          this.detailData = detailData;
          this.detailData.answerList = this.detailData.answerList.splice(0, 3);
          this.detailData.recommendList = this.detailData.recommendList.splice(
            0,
            3
          );
          console.log(this.detailData);
          if (this.detailData.classifyName != "") {
            this.tags = this.detailData.classifyName.split(",");
          }
        });
    },
  },
  computed: {},
  created() {
    this.detailShow();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.tutorDetail {
  background: #f7f7f7;
  padding: 39px 0 80px;
  box-sizing: border-box;
  i {
    font-style: normal;
  }
  .main {
    width: 1200px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .left {
      background: #fff;
      width: 800px;
      padding-bottom: 26px;
      .center {
        width: 737px;
        margin: 0 auto;
        box-sizing: border-box;
        border-bottom: 1px solid #eeeeee;
        padding: 30px 0px 35px;
        box-sizing: border-box;
        .flex_cup {
          display: flex;
          position: relative;
          .infor {
            padding: 8px 0 0;
            h3 {
              font-size: 36px;
              color: #333333;
              margin-bottom: 11px;
              width: 260px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            h4 {
              font-weight: normal;
              font-size: 24px;
              margin-bottom: 12px;
              color: #333333;
              width: 260px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .tag {
              display: flex;
              justify-content: flex-start;
              margin: 0 8px 73px 0;
              span {
                padding: 5px 12px;
                box-sizing: border-box;
                text-align: center;
                font-size: 14px;
                color: #ff7b00;
                display: block;
                border-radius: 16px;
                background: rgba(255, 123, 0, 0.1);
                margin-right: 8px;
              }
            }
            .button {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 18px;
              position: absolute;
              bottom: 30px;
              span {
                width: 160px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 4px;
                font-size: 16px;
                display: block;
                border: 1px solid #ff9e44;
                cursor: pointer;
              }
              span:first-child {
                margin-right: 10px;
                color: #fff;
                background: linear-gradient(
                  91.01deg,
                  #ff7b00 0.75%,
                  #ff9e44 99.34%
                );
              }
              span:last-child {
                color: #ff7b00;
                background: #fff;
              }
            }
            .p {
              font-size: 14px;
              color: #999999;
              position: absolute;
              bottom: 12px;
              i {
                color: #666;
                font-size: 14px;
                display: inline-block;
                font-style: normal;
              }
            }
          }
        }

        .avatar {
          width: 240px;
          height: 280px;
          margin-right: 30px;
          img {
            width: 100%;
          }
        }
      }
      .center2 {
        padding-bottom: 23px;
        padding-top: 20px;
        .part {
          margin-bottom: 22px;
        }
        .part:last-child {
          margin-bottom: 0px;
        }
        h5 {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12px;
          span {
            width: 26px;
            height: 26px;
            display: block;
            margin-right: 10px;
            img {
              width: 100%;
            }
          }
          p {
            font-size: 24px;
            color: #333;
          }
        }
        .detail {
          font-size: 16px;
          color: #333;
          line-height: 22px;
        }
      }
      .bottom {
        padding: 44px 16px 0;
        box-sizing: border-box;
        .moreinfor {
          position: relative;
          text-align: center;
          cursor: pointer;
          .codes {
            z-index: 999;
            width: 198px;
            height: 210px;
            position: absolute;
            left: 50%;
            margin-left: -99px;
            top: -170px;
            img {
              width: 100%;
            }
          }
          .icon {
            width: 36px;
            height: 36px;
            margin: 0 auto;
            img {
              width: 100%;
            }
          }
          p {
            font-size: 16px;
            font-weight: 500;
            color: #999999;
            margin-top: 5px;
          }
        }
        h3 {
          font-size: 24px;
          color: #333333;
          text-align: center;
          margin-bottom: 18px;
        }
        .card {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-bottom: 23px;
          .item {
            width: 240px;
            height: 203px;
            padding: 23px 11px 20px;
            box-sizing: border-box;
            margin-right: 24px;
            border-radius: 4px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
            position: relative;
            .top {
              display: flex;
              justify-content: flex-start;
              .tag {
                width: 20px;
                height: 20px;
                margin-right: 3px;
                flex-shrink: 0;
                img {
                  width: 100%;
                }
              }

              p {
                font-size: 16px;
                color: #333333;
                line-height: 22px;
              }
            }
            .float {
              position: absolute;
              bottom: 20px;
              left: 11px;
              .answer {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .audiocontrol {
                  display: none;
                }
                .img_cup {
                  width: 26px;
                  height: 26px;
                  border-radius: 26px;
                  overflow: hidden;
                  margin-right: 15px;
                  img {
                    width: 100%;
                  }
                }
                .audio {
                  width: 177px;
                  height: 30px;
                  position: relative;
                  cursor: pointer;
                  background: rgba(255, 123, 0, 0.1);
                  border-radius: 0px 10px 10px 10px;
                  background: url(../../assets/audioplay.png) no-repeat;
                  background-size: contain;
                  /* 动画代码 */
                  // @keyframes playing {
                  //   from {
                  //     background: url(../../assets/line1.png) no-repeat;
                  //   background-size: contain;

                  //   }
                  //   to {
                  //     background: url(../../assets/line2.png) no-repeat;
                  //   background-size: contain;

                  //   }
                  // }
                  .line {
                    width: 42px;
                    height: 20px;
                    position: absolute;
                    left: 30px;
                    top: 3px;
                    background: url(../../assets/line1.png) no-repeat;
                    background-size: contain;
                  }
                  // .pause{
                  //     background: url(../../assets/line1.png) no-repeat;
                  //   background-size: contain;
                  // }
                  // .playing{
                  //   animation-name: playing;
                  //   animation-duration: 0.5s;
                  //   animation-iteration-count: infinite;
                  // }
                }
                i.time {
                  position: absolute;
                  top: 6.5px;
                  right: 13px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #ff7b00;
                }
              }
            }
          }
          .item:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .right {
      .recommend {
        h4 {
          margin: 23px 0;
          font-size: 24px;
          text-align: center;
          font-weight: 700;
          color: #333333;
        }
        .list {
          .item {
            padding: 22px 20px 16px 18px;
            box-sizing: border-box;
            margin-bottom: 10px;
            width: 370px;
            height: 164px;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            .top {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 18px;
              .img_c {
                width: 70px;
                height: 70px;
                margin-right: 14px;
                border-radius: 70px;
                overflow: hidden;
                flex-shrink: 0;
                img {
                  width: 100%;
                }
              }
              .text {
                h5 {
                  font-size: 22px;
                  font-weight: 700;
                  color: #333333;
                  width: 260px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  margin-bottom: 5px;
                }
                p {
                  width: 260px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  font-size: 16px;
                  font-weight: 500;
                  color: #333333;
                }
              }
            }
            .btn {
              display: flex;
              justify-content: space-between;
              align-items: center;
              p {
                font-size: 14px;
                color: #999;
              }
              span {
                width: 123px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                background: linear-gradient(93deg, #ff7b00 20%, #ff9e44 100%);
                border-radius: 19px;
                display: block;
                font-size: 16px;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }
      }
      .onlineMsg {
        width: 370px;
        height: 435px;
        background: url(../../assets/cardbg.png) no-repeat;
        background-size: contain;
        position: relative;
        .floatMsg {
          position: absolute;
          bottom: 101px;
          left: 43px;
          cursor: pointer;
          p {
            font-size: 30px;
            font-weight: 700;
            color: #333333;
            margin-bottom: 18px;
            span {
              display: inline-block;
              color: #ff7b00;
              font-weight: 700;
              font-size: 16px;
              cursor: pointer;
            }
          }
          i {
            font-size: 16px;
            width: 160px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            background: linear-gradient(92deg, #ff7b00 20%, #ff9e44 100%);
            border-radius: 20px;
            display: block;
          }
        }
      }
    }
  }
}
</style>